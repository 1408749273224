
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "LoanDetails",

  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээлийн дэлгэрэнгүй");
    });

    const role = computed(() => {
      return store.getters.getRoleNames;
    });

    return {
      role
    };
  },
  mounted() {
    if (
      this.role.includes("ROLE_MORE_TLOAN") ||
      this.role.includes("ROLE_MORE_MORT_INFO") ||
      this.role.includes("ROLE_PUB_NEWS_LOAN_PAYMENT") ||
      this.role.includes("ROLE_ADMIN")
    )
      this.getLoanDetails();
  },
  data() {
    const idArr = this.b64DecodeUnicode(this.$route.params.id)
      .toString()
      .split("@");
    const loancode = idArr[0];
    const orgcode = idArr[1];
    const customercode = idArr[2];

    return {
      loancode,
      orgcode,
      customercode,
      dataLoading: true,
      deleteRelationButtonLoad: "off",
      loanDetailsNotFound: false,
      updateLoan: false,
      emptyText: "Өгөгдөл байхгүй байна.",
      loanDetails: {},
      requiredData: {},
      loanPayGraph: [],
      loanPayGraphPage: 1,
      loanPayGraphPageSize: 10,
      loanMortgage: [],
      updateLoanInput: {
        advamount: 0,
        starteddate: "",
        expdate: "",
        currencycode: "",
        balance: 0,
        loanclasscode: "",
        updatedexpdate: "",
        paidDate: ""
      },
      getCoCitizen: {
        data: [],
        loader: true
      },
      getCoOrg: {
        data: [],
        loader: true
      },
      getCitizenRelationInfoData: {
        data: [],
        loader: true
      },
      getStaffInfoData: {
        data: [],
        loader: true
      },
      getBankRelationInfoData: {
        data: [],
        loader: true
      },
      getOrgRelationInfoData: {
        data: [],
        loader: true
      },
      getShareHolderOrgInfoData: {
        data: [],
        loader: true
      },
      getShareHolderCitizenInfoData: {
        data: [],
        loader: true
      }
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.loanPayGraphPage = val;
    },
    handleChange(value) {
      this.loanPayGraphPageSize = value;
    },
    deleteLoan() {
      Swal.fire({
        text: this.loancode + " - дугаартай зээлийг устгах хүсэлт үүсгэх үү?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Буцах",
        confirmButtonText: "Үүсгэх"
      }).then(async result => {
        if (result.isConfirmed) {
          const params = {
            loancode: this.loancode,
            tablename: "TLoan",
            type: "loan",
            field: "loancode",
            customercode: this.customercode,
            customername: this.loanDetails["customername"],
            registerno: this.loanDetails["registerno"],
            orgcode: this.loanDetails["orgcode"],
            purpose: "02"
          };
          await ApiService.post("refcustomerdetailsrel", { params }).then(
            res => {
              const result = res.data;
              if (result.code === 0) {
                ElNotification({
                  title: "Алдаа",
                  message: result.error,
                  type: "error"
                });
              } else if (result.code === 401) {
                ElNotification({
                  title: "Анхааруулга",
                  message: result.warning,
                  type: "warning"
                });
              } else {
                ElNotification({
                  title: "Амжилттай",
                  message: result.response,
                  type: "success"
                });
              }
            }
          );
        }
      });
    },
    async deleteRelation(row, iscitizen) {
      this.deleteRelationButtonLoad = "on";
      const params = {
        registerno: row.registerno,
        customercode: row.customercode,
        loancode: row.loancode
      };
      await ApiService.delete("refcustomerdetailsrel", { params })
        .then(res => {
          const result = res.data;
          if (result.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: result.error,
              type: "error"
            });
          } else if (result.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: result.warning,
              type: "warning"
            });
          } else {
            ElNotification({
              title: "Амжилттай",
              message: result.response,
              type: "success"
            });
          }
        })
        .finally(() => {
          this.deleteRelationButtonLoad = "off";
          this.getCoCitizenOrg(iscitizen);
        });
    },
    async updateLoanAction() {
      const data: any = [];
      const params = {
        loancode: this.loancode,
        type: "loan",
        purpose: "01"
      };

      if (
        this.loanDetails["advamount"]
          ?.toString()
          .localeCompare(this.updateLoanInput.advamount) !== 0 &&
        this.loanDetails["advamount"]
          ?.toString()
          .localeCompare(this.updateLoanInput.advamount) !== undefined
      ) {
        data.push({
          newVal: this.updateLoanInput.advamount,
          oldVal: this.loanDetails["advamount"],
          field: "advamount",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "number"
        });
      }
      let date = this.loanDetails["starteddate"]
        ? this.loanDetails["starteddate"].localeCompare(
            moment(this.updateLoanInput.starteddate)
              .format("YYYY/MM/DD HH:mm:ss")
              .toString()
          )
        : this.updateLoanInput.starteddate
        ? 1
        : 0;

      if (date === -1 || date === 1) {
        data.push({
          newVal: !this.updateLoanInput.starteddate
            ? ""
            : moment(this.updateLoanInput.starteddate)
                .format("YYYY/MM/DD HH:mm:ss")
                .toString(),
          oldVal: this.loanDetails["starteddate"],
          field: "starteddate",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "date"
        });
      }

      date = this.loanDetails["expdate"]
        ? this.loanDetails["expdate"].localeCompare(
            moment(this.updateLoanInput.expdate)
              .format("YYYY/MM/DD")
              .toString()
          )
        : this.updateLoanInput.expdate
        ? 1
        : 0;

      if (date === -1 || date === 1) {
        data.push({
          newVal: !this.updateLoanInput.expdate
            ? ""
            : moment(this.updateLoanInput.expdate)
                .format("YYYY/MM/DD")
                .toString(),
          oldVal: this.loanDetails["expdate"],
          field: "expdate",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "date"
        });
      }

      if (
        this.loanDetails["currencycode"]?.localeCompare(
          this.updateLoanInput.currencycode
        ) !== 0 &&
        this.loanDetails["currencycode"]?.localeCompare(
          this.updateLoanInput.currencycode
        ) !== undefined
      ) {
        data.push({
          newVal: this.updateLoanInput.currencycode,
          oldVal: this.loanDetails["currencycode"],
          field: "currencycode",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "text"
        });
      }

      if (
        this.loanDetails["balance"]
          ?.toString()
          .localeCompare(this.updateLoanInput.balance) !== 0 &&
        this.loanDetails["balance"]
          ?.toString()
          .localeCompare(this.updateLoanInput.balance) !== undefined
      ) {
        data.push({
          newVal: this.updateLoanInput.balance,
          oldVal: this.loanDetails["balance"],
          field: "balance",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "number"
        });
      }

      if (
        this.loanDetails["loanclasscode"]?.localeCompare(
          this.updateLoanInput.loanclasscode
        ) !== 0 &&
        this.loanDetails["loanclasscode"]?.localeCompare(
          this.updateLoanInput.loanclasscode
        ) !== undefined
      ) {
        data.push({
          newVal: this.updateLoanInput.loanclasscode,
          oldVal: this.loanDetails["loanclasscode"],
          field: "loanclasscode",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "text"
        });
      }

      date = this.loanDetails["updatedexpdate"]
        ? this.loanDetails["updatedexpdate"].localeCompare(
            moment(this.updateLoanInput.updatedexpdate)
              .format("YYYY/MM/DD")
              .toString()
          )
        : this.updateLoanInput.updatedexpdate
        ? 1
        : 0;

      if (date === -1 || date === 1) {
        data.push({
          newVal: !this.updateLoanInput.updatedexpdate
            ? ""
            : moment(this.updateLoanInput.updatedexpdate)
                .format("YYYY/MM/DD")
                .toString(),
          oldVal: this.loanDetails["updatedexpdate"],
          field: "updatedexpdate",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "date"
        });
      }

      date = this.loanDetails["paidDate"]
        ? this.loanDetails["paidDate"].localeCompare(
            moment(this.updateLoanInput.paidDate)
              .format("YYYY/MM/DD")
              .toString()
          )
        : this.updateLoanInput.paidDate
        ? 1
        : 0;

      if (date === -1 || date === 1) {
        data.push({
          newVal: !this.updateLoanInput.paidDate
            ? ""
            : moment(this.updateLoanInput.paidDate)
                .format("YYYY/MM/DD")
                .toString(),
          oldVal: this.loanDetails["paidDate"],
          field: "paidDate",
          table: "TLoan",
          custname: this.loanDetails["customername"],
          registerno: this.loanDetails["registerno"],
          keyvalue: this.loancode,
          fieldtype: "date"
        });
      }
      await ApiService.postWithRawBody(
        "refcustomerdetailsrel",
        { data },
        { params }
      ).then(res => {
        const result = res.data;
        if (result.code === 0) {
          ElNotification({
            title: "Алдаа",
            message: result.error,
            type: "error"
          });
        } else if (result.code === 401) {
          ElNotification({
            title: "Анхааруулга",
            message: result.warning,
            type: "warning"
          });
        } else {
          ElNotification({
            title: "Амжилттай",
            message: result.response,
            type: "success"
          });
          this.updateLoan = !this.updateLoan;
        }
      });
    },
    async getRelInfo(type) {
      this.getCitizenRelationInfoData.loader = true;
      this.getOrgRelationInfoData.loader = true;
      this.getStaffInfoData.loader = true;
      this.getBankRelationInfoData.loader = true;
      this.getShareHolderOrgInfoData.loader = true;
      this.getShareHolderCitizenInfoData.loader = true;
      await ApiService.get(
        "refcustomerdetailsrel",
        `?type=${type}&loancode=${this.loancode}`
      )
        .then(res => {
          const result = res.data;
          if (result.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: result.error,
              type: "error"
            });
          } else if (result.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: result.warning,
              type: "warning"
            });
          } else {
            if (result.response.length === 0) {
              ElNotification({
                message: "Хоосон байна.",
                type: "info"
              });
            } else {
              if (type === "getCitizenRelationInfo")
                this.getCitizenRelationInfoData.data = result.response;
              else if (type === "getOrgRelationInfo")
                this.getOrgRelationInfoData.data = result.response;
              else if (type === "getStaffInfo")
                this.getStaffInfoData.data = result.response;
              else if (type === "getBankRelationInfo")
                this.getBankRelationInfoData.data = result.response;
              else if (type === "getShareHolderOrgInfo")
                this.getShareHolderOrgInfoData.data = result.response;
              else if (type === "getShareHolderCitizenInfo")
                this.getShareHolderCitizenInfoData.data = result.response;
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.getCitizenRelationInfoData.loader = false;
          this.getOrgRelationInfoData.loader = false;
          this.getStaffInfoData.loader = false;
          this.getBankRelationInfoData.loader = false;
          this.getShareHolderOrgInfoData.loader = false;
          this.getShareHolderCitizenInfoData.loader = false;
        });
    },
    async getLoanDetails() {
      this.dataLoading = true;
      await ApiService.get(
        "refloandetails",
        `?loancode=${this.loancode}&customercode=${this.customercode}&orgcode=${this.orgcode}`
      )
        .then(res => {
          const result = res.data;
          if (result.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: result.error,
              type: "error"
            });
          } else if (result.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: result.warning,
              type: "warning"
            });
          } else if (result.code === 2) {
            ElNotification({
              title: "Анхааруулга",
              message: result.warning,
              type: "warning"
            });
          } else {
            this.requiredData = result.response.requireddata;
            const loanDetails = result.response.loandetails;
            const loanPayGraph = result.response.graphdetails;
            const loanMortgage = result.response.mortgagedetails;
            if (loanPayGraph.code === 0) {
              if (
                this.role.includes("ROLE_PUB_NEWS_LOAN_PAYMENT") ||
                this.role.includes("ROLE_ADMIN")
              )
                ElNotification({
                  title: "Анхааруулга",
                  message: "Эргэн төлөлтийн график хоосон байна.",
                  type: "warning"
                });
            } else if (loanPayGraph.code === -401) {
              ElNotification({
                title: "Анхааруулга",
                message: loanPayGraph.warning,
                type: "warning"
              });
            } else if (loanPayGraph.code === -1) {
              ElNotification({
                title: "Анхааруулга",
                message: loanPayGraph.result + " /Зээл төлөх/",
                type: "warning"
              });
            } else {
              this.loanPayGraph = loanPayGraph.result;
            }
            if (loanMortgage.code === 0) {
              if (
                this.role.includes("ROLE_PUB_NEWS_LOAN_PAYMENT") ||
                this.role.includes("ROLE_ADMIN")
              )
                ElNotification({
                  title: "Анхааруулга",
                  message: "Барьцаа хөрөнгийн мэдээлэл хоосон байна.",
                  type: "warning",
                  customClass: "el-text-success el-alert--success"
                });
            } else if (loanMortgage.code === -401) {
              ElNotification({
                title: "Анхааруулга",
                message: loanMortgage.warning,
                type: "warning"
              });
            } else if (loanMortgage.code === -1) {
              ElNotification({
                title: "Анхааруулга",
                message: loanMortgage.result + " /Барьцаа хөрөнгө/",
                type: "warning"
              });
            } else {
              this.loanMortgage = loanMortgage.result;
            }
            if (loanDetails.code !== 0) {
              this.loanDetailsNotFound = false;
              this.loanDetails = loanDetails.result[0];
              this.updateLoanInput.expdate = loanDetails.result[0].expdate;
              this.updateLoanInput.balance = loanDetails.result[0].balance;
              this.updateLoanInput.loanclasscode =
                loanDetails.result[0].loanclasscode;
              this.updateLoanInput.advamount = loanDetails.result[0].advamount;
              this.updateLoanInput.paidDate = loanDetails.result[0].paidDate;
              this.updateLoanInput.starteddate =
                loanDetails.result[0].starteddate;
              this.updateLoanInput.currencycode =
                loanDetails.result[0].currencycode;
              this.updateLoanInput.updatedexpdate =
                loanDetails.result[0].updatedexpdate;
            } else {
              this.loanDetailsNotFound = true;
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    async getCoCitizenOrg(iscitizen) {
      if (iscitizen) {
        this.getCoCitizen.loader = true;
      } else {
        this.getCoOrg.loader = true;
      }
      await ApiService.get(
        "refcocitizenorg",
        `?loancode=${this.loancode}&customercode=${this.customercode}&iscitizen=${iscitizen}`
      )
        .then(res => {
          const result = res.data;
          if (result.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: result.error,
              type: "error"
            });
          } else if (result.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: result.warning,
              type: "warning"
            });
          } else {
            if (iscitizen) {
              this.getCoCitizen.data = result.response;
            } else {
              this.getCoOrg.data = result.response;
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          if (iscitizen) {
            this.getCoCitizen.loader = false;
          } else {
            this.getCoOrg.loader = false;
          }
        });
    }
  }
});
